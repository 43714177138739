import authentication from '@kdpw/msal-b2c-react'
import decode from 'jwt-decode'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'

const settings = {
  tenant: process.env.REACT_APP_TENANT_NAME,
  instance: process.env.REACT_APP_INSTANCE,
  signInPolicy: process.env.REACT_APP_SIGN_IN_POLICY,
  applicationId: process.env.REACT_APP_APP_ID,
  cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
  scopes: [process.env.REACT_APP_SCOPES],
  postLogoutRedirectUri: window.location.origin,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  validateAuthority: false,
  resetPolicy: process.env.REACT_APP_SIGN_IN_POLICY
}
export const config = () => {
  let hash = window.location.hash

  if (hash) {
    let objMessage = {}
    hash = hash.substring(1).split('&')
    hash.map(item => {
      const i = item.split('=')
      objMessage[i[0]] = i[1]
      return item
    })
    if (objMessage.error_description) {
      if (objMessage.error_description.search('AADB2C90118') >= 0) {
        window.location.href = `https://${process.env.REACT_APP_TENANT_NAME.substring(0, process.env.REACT_APP_TENANT_NAME.indexOf("."))}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}/oauth2/v2.0/authorize?p=${process.env.REACT_APP_FORGOT_PASSWORD_POLICY}&client_id=${process.env.REACT_APP_APP_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=openid&response_type=id_token&prompt=login`
        console.log(window.location.href)
      } else {
        window.location.href = window.location.origin
        console.log(window.location.href)
      }
    } else {
      return settings
    }
  } else {
    if (window.location.search === '?forgot-password') {
      //return { ...settings, signInPolicy: process.env.REACT_APP_FORGOT_PASSWORD_POLICY }
      window.location.href = `https://${process.env.REACT_APP_TENANT_NAME.substring(0, process.env.REACT_APP_TENANT_NAME.indexOf("."))}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}/oauth2/v2.0/authorize?p=${process.env.REACT_APP_FORGOT_PASSWORD_POLICY}&client_id=${process.env.REACT_APP_APP_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=openid&response_type=id_token&prompt=login`
      console.log(window.location.href)
    }
    console.log(settings)
    return settings
  }
}
export default class AAD_B2CService {
  loggedIn = () => {
    if (authentication.getAccessToken())
      return true;
    return false;
  }
  logout = () => {
    authentication.signOut();
  }
  getToken = () => {
    return authentication.getAccessToken();
  }
  getClaims = () => {
    return decode(this.getToken());
  }
  getUser = () => {
    const claims = decode(authentication.getAccessToken());
    return {
      id: 1, //claims.oid1,
      newUser: claims.newUser,
      name: claims.name,
      streetAddress: claims.streetAddress,
      city: claims.city,
      given_name: claims.given_name,
      extension_Telefono: claims.extension_Telefono,
      emails: claims.emails[0],
    };
  }
  getCustomer = () => {
    return process.env.REACT_APP_CUSTOMER
  }
  getApi = async ({ method, url, body }) => {
    const headers = new Headers({
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
      'Content-Type': 'application/json'
    })
    const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`, {
      method: method || 'GET',
      headers,
      body
    })
    return await response.json()
  }
  getApiGet = async url => {
    const headers = new Headers({
      'Content-Type': 'application/json'
    })
    return await fetch(url, headers)
  }
}
const httpLink = createHttpLink({
  // uri: `${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/web/graphql`,
  uri: `${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/graphql`,
});
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      // 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
      // 'Access-Control-Allow-Origin' : '*',
      // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
    }
  }
})
const cache = new InMemoryCache()
export const clientApollo = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  fetchOptions: {
    mode: 'no-cors',
  }
})