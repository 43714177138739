import React, { createContext, useState, useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import { initialState, reducer } from "./reducer"
import AAD_B2CService from './azure';

export const StoreContext = createContext()

const states = {
  listFormats: ['PDF', 'EXCEL', 'CSV'],
  login: "",
  customer: "",
  role: {},
  commerce: {},
  commerceData: {},
  userData: {},
  userRoleData: {},
  loadingData: {}
}

const StoreProvider = props => {
  let stateValues = {}
  for (const state in states) {
    if (states.hasOwnProperty(state)) {
      const element = states[state];
      [stateValues[state], stateValues[`set${state.charAt(0).toUpperCase()}${state.substr(1)}`]] = useState(element)
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const fetchBackend = useCallback(async params => {
    const AAD_B2CServiceI = new AAD_B2CService()
    return await AAD_B2CServiceI.getApi(params)
  })
  return (
    <StoreContext.Provider value={{
      ...stateValues,
      state,
      dispatch,
      fetchBackend
    }}>
      {props.children}
    </StoreContext.Provider>
  )
}
StoreProvider.propTypes = {
  children: PropTypes.object.isRequired,
}
export default StoreProvider
