export const reducer = (state, action) => {
  switch (action.type) {
    case 'snackSuccess':
      return {
        ...state,
        snackbar: {
          message: action.message,
          open: true,
          variant: "success"
        }
      }
    case 'snackInfo':
      return {
        ...state,
        snackbar: {
          message: action.message,
          open: true,
          variant: "info"
        }
      }
    case 'snackError':
      return {
        ...state,
        snackbar: {
          message: action.message,
          open: true,
          variant: "error"
        }
      }
    case 'snackClose':
      return {
        ...state,
        snackbar: {
          message: "",
          open: false,
          variant: "info"
        }
      }
    default:
      return state
  }
}
export const initialState = {
  snackbar: {
    message: "",
    open: false,
    variant: "success"
  }
}