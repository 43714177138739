import { createTheme } from '@material-ui/core/styles'
import {
  brown as SecundaryColor,
  //green as PrimaryColor,
  red as SuccessColor
} from '@material-ui/core/colors'

export default createTheme({
  palette: {
    primary: { 500: process.env.REACT_APP_CUSTOMER === "PAYNET" ? "#ea0029" : "#2196f3" },
    secondary: SecundaryColor,
    background: { drawer: "#fff" },
    client: { icons: '#ea0029' },
    success: SuccessColor
  },
  typography: {
    useNextVariants: true,
  },
});

export const localizationMaterialTable = {
  header: { actions: "Acciones" },
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "Filas",
    labelRowsPerPage: "Filas por página:",
    firstAriaLabel: "Primera página	",
    firstTooltip: "Primera página	",
    previousAriaLabel: "Pagina anterior",
    previousTooltip: "Pagina anterior",
    nextAriaLabel: "Siguiente página",
    nextTooltip: "Siguiente página",
    lastAriaLabel: "Última página	",
    lastTooltip: "Última página	",
  },
  toolbar: {
    nRowsSelected: "{0} fila(s) seleccionada(s)",
    showColumnsTitle: "Mostrar columnas",
    showColumnsAriaLabel: "showColumnsAriaLabel",
    exportTitle: "Exportar",
    exportAriaLabel: "Exportar",
    exportName: "Exportar a Excel",
    exportCSVName: "Exportar a Excel",
    exportPDFName: "Exportar a pdf",
    searchTooltip: "Buscar",
    addRemoveColumns: "Añadir o eliminar columnas"
  },
  body: {
    emptyDataSourceMessage: "No hay registros que mostrar",
    addTooltip: "Agregar",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    filterRow: { filterTooltip: "Filtro" },
    editRow: {
      deleteText: "¿Estás seguro de eliminar esta fila?",
      cancelTooltip: "Cancelar",
      saveTooltip: "Guardar",
    },
    grouping: { placeholder: "Arrastrar encabezados ..." }
  }
}