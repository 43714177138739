import React from 'react';
import PropTypes from 'prop-types'
import { withRouter, Route } from "react-router-dom";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Hidden from '@material-ui/core/Hidden';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
//import Logo from './Logo';
import MenuItem from '@material-ui/core/MenuItem';
import SnackbarComponent from './Snackbar';
import * as icons from '@material-ui/icons'

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: theme.palette.primary.main,
  },
  active: {
    color: theme.palette.primary.main
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none'
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.background.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  iconHeader: {
    color: theme.palette.primary.contrastText
  }
});

const DrawerPart = ({ sm, classes, listMenu, handleItem, handleDrawerClose, open }) => (
  <Drawer
    variant={!sm ? "permanent" : "temporary"}
    classes={{
      paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
    }}
    open={open}
  >
    <div className={classes.toolbarIcon}>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
    <Divider />
    <List>
      {listMenu.map((item, index) => (
        <Route
          path={item.path}
          exact={item.menuExact}
          key={index}
        >
          {({ match }) => {
            const Icon = icons[item.icon]
            return (
              <ListItem
                onClick={() => handleItem(item, sm)}
                selected={match ? true : false}
                button>
                <ListItemIcon>
                  <Icon color='primary' />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography className={match ? classes.active : ""} variant="subtitle2" gutterBottom>{item.title}</Typography>}
                />
              </ListItem>
            )
          }}
        </Route>
      ))}
    </List>
  </Drawer >
)

DrawerPart.propTypes = {
  sm: PropTypes.bool,
  classes: PropTypes.object,
  listMenu: PropTypes.array,
  handleItem: PropTypes.func,
  handleDrawerClose: PropTypes.func,
  open: PropTypes.bool,
}

class Dashboard extends React.Component {
  state = {
    open: true,
    anchorEl: null,
  };

  handleDrawerToggle = () => {
    // this.setState({ open: true });
    this.setState((state) => { return { open: !state.open } })

  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  logout = () => {
    this.setState({ anchorEl: null });
    this.props.handleLogout()
  }
  handleItem = (item, sm) => {
    this.props.history.push(item.path)
    sm && this.setState({ open: false })
  }
  render() {
    const {
      classes,
      handleCloseSnackbar,
      snackbar,
      user,
      //customer
    } = this.props;
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div id="HeaderTool" className={classes.root}>
        <CssBaseline />
        <AppBar
          id="HeaderTool2"
          position="absolute"
          className={classNames(classes.appBar)}
        >
          <Toolbar id="HeaderTool3" disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classNames(
                classes.menuButton,
              )}
            >
              <MenuIcon className={classes.iconHeader} />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {/*<Logo height={70} logoname={customer === "PAYNET" ? "logo_paynet.png": "logo_blanco.png"} />*/}
              TranXaction
            </Typography>
            <Typography color="inherit"
              noWrap
              variant="body2"
              gutterBottom
            >
              {user}
            </Typography>
            <div>
              <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <AccountCircle className={classes.iconHeader} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
              >
                {/* <MenuItem onClick={this.handleClose}>Mi Cuenta</MenuItem> */}
                <MenuItem onClick={this.logout}>Cerrar Sesión</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Hidden xsDown>
          <DrawerPart
            {...this.props}
            handleDrawerClose={this.handleDrawerClose}
            open={this.state.open}
            handleItem={this.handleItem}
          />
        </Hidden>
        <Hidden smUp>
          <DrawerPart
            handleDrawerClose={this.handleDrawerClose}
            sm
            open={this.state.open}
            {...this.props}
            handleItem={this.handleItem}
          />
        </Hidden>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {this.props.children}
        </main>
        <SnackbarComponent
          handleClose={handleCloseSnackbar}
          message={snackbar.message}
          open={snackbar.open}
          variant={snackbar.variant}
        />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  listMenu: PropTypes.array.isRequired,
  children: PropTypes.object,
  history: PropTypes.object,
  handleLogout: PropTypes.func,
  handleCloseSnackbar: PropTypes.func.isRequired,
  snackbar: PropTypes.shape({
    message: PropTypes.string,
    open: PropTypes.bool,
    variant: PropTypes.string,
  }).isRequired,
  user: PropTypes.string,
  customer: PropTypes.string,
};

export default withRouter(withStyles(styles)(Dashboard));
